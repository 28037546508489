<template>
  <v-layout align-center justify-center row fill-height class="mb-2 mt-4">
    <router-link to="/login">
      <img src="@/assets/ffly4u_desktop.png">
    </router-link>
  </v-layout>
</template>

<script>
export default {
  name: 'Ffly4uAuthLogo',
};
</script>

<style>

</style>
