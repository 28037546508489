<template>
  <v-container grid-list-md fill-height>
    <v-layout align-center justify-center>
      <v-flex xs10 sm7 md5>
        <v-card>
          <v-layout align-start justify-center row fill-height>
            <v-flex xs9 sm9 md9>
              <Ffly4uAuthLogo />
              <DropdownTranslate />
              <h1>{{ $t('auth.login.title') }}</h1>
              <p>
                {{ $t('auth.login.description') }}
              </p>
              <v-form
                ref="form"
                v-model="validForm"
                @submit.prevent="validate()"
              >
                <v-text-field
                  autocomplete="username"
                  v-model="username"
                  :label="$t('auth.username')"
                  :rules="[rules.required, rules.username]"
                  backgroundColor="white"
                  solo
                  append-icon="mdi-account-outline"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  autocomplete="current-password"
                  :append-icon="passwordVisibility ? 'visibility' : 'visibility_off'"
                  :type="passwordVisibility ? 'text' : 'password'"
                  name="input-10-1"
                  :label="$t('auth.password')"
                  :rules="[rules.required, rules.min, rules.password]"
                  v-on:click:append="passwordVisibility = !passwordVisibility"
                  backgroundColor="white"
                  solo
                ></v-text-field>
                <v-layout align-center justify-center row fill-height>
                    <!-- @click="validate()" -->
                  <v-btn
                    :disabled="loginBtnDisabled || !validForm"
                    type="submit"
                    class="white--text"
                    color="primary">{{ $t('auth.login.button') }}
                  </v-btn>
                </v-layout>
                <div class="my-6">
                  <p class="error--text" v-if="error">{{ $t('auth.login.error') }} </p>
                </div>
                <div class="my-6">
                  <v-layout align-end justify-space-around row fill-height>
                    <router-link to="/reset">
                      {{ $t('auth.lostPassword') }}
                    </router-link>
                  </v-layout>
                </div>
              </v-form>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Ffly4uAuthLogo from '@/components/Ffly4uAuthLogo.vue';

export default {
  name: 'Login',
  components: { Ffly4uAuthLogo },
  data() {
    return {
      validForm: true,
      passwordVisibility: false,
      username: decodeURIComponent(this.$route.query.username || ''),
      password: '',
      error: false,
      rules: {
        required: (value) => !!value || this.$t('misc.required'),
        min: (v) => v.length >= 8 || this.$t('misc.min8Chars'),
        username: (v) => (v.length >= 1 && v.length <= 128) || this.$t('misc.invalidUsername'),
        password: (value) => {
          const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
          return pattern.test(value) || this.$t('misc.invalidPassword');
        },
      },
      loginBtnDisabled: false,
    };
  },
  methods: {
    /**
    * Ensure that form is valid before making backend request
    */
    validate() {
      this.error = false;
      if (this.$refs.form.validate()) {
        this.login();
      }
    },
    async login() {
      const credentials = { username: this.username, password: this.password };
      this.loginBtnDisabled = true;
      try {
        const {
          loggedIn,
          redirect,
        } = await this.$store.dispatch('auth/LOGIN', { credentials });
        if (!loggedIn && redirect) {
          this.$router.push(redirect);
        } else if (loggedIn) {
          // This allows to reload Vue-router with the settings
          window.location = localStorage.nextPath || '/';
        } else {
          this.error = true;
          this.loginBtnDisabled = false;
        }
      } catch (error) {
        this.error = true;
        if (process.env.NODE_ENV !== 'production') {
          console.error(error);
        }
        this.loginBtnDisabled = false;
      }
    },
  },
  metaInfo() {
    return {
      title: this.$t('auth.login.title'),
    };
  },
};
</script>
